@font-face {
    font-family: "creators-icons";
    src:  url('https://cdn.creators3d.com/fonts/creators3d.eot?nt5fl5');
    src:  url('https://cdn.creators3d.com/fonts/creators3d.eot?nt5fl5#iefix') format('embedded-opentype'),
        url('https://cdn.creators3d.com/fonts/creators3d.ttf?nt5fl5') format('truetype'),
        url('https://cdn.creators3d.com/fonts/creators3d.woff?nt5fl5') format('woff'),
        url('https://cdn.creators3d.com/fonts/creators3d.svg?nt5fl5#creators3d') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'creators-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-20 {
    font-size: 20px;
}

.icon-upload:before {
    content: "\e916";
}
.icon-download:before {
    content: "\e915";
}
.icon-animation:before {
    content: "\e900";
}
.icon-behance:before {
    content: "\e901";
}
.icon-bonus_icon:before {
    content: "\e902";
}
.icon-build:before {
    content: "\e903";
}
.icon-dribbble:before {
    content: "\e904";
}
.icon-envelope:before {
    content: "\e905";
}
.icon-envelope_onboarding:before {
    content: "\e906";
}
.icon-geometry:before {
    content: "\e907";
}
.icon-lighting:before {
    content: "\e908";
}
.icon-location:before {
    content: "\e909";
}
.icon-render:before {
    content: "\e90a";
}
.icon-rigging:before {
    content: "\e90b";
}
.icon-skype:before {
    content: "\e90c";
}
.icon-star:before {
    content: "\e90d";
}
.icon-star_grey:before {
    content: "\e90e";
}
.icon-star_half:before {
    content: "\e90f";
}
.icon-texture:before {
    content: "\e910";
}
// <span class="icon-v"><span class="path1"></span><span class="path2"></span></span>
// <span class="icon-v"><span class="path2"></span></span>
.icon-v .path1:before {
    content: "\e911";
}
.icon-v .path2:before {
    content: "\e912";
    left: -1px;
    position: relative;
    // margin-left: -1em;
}
// <span class="icon-x"><span class="path1"></span><span class="path2"></span></span>
.icon-x .path1:before {
    content: "\e913";
}
.icon-x .path2:before {
    content: "\e914";
    margin-left: -1em;
}