/* You can add global styles to this file, and also import other style files */
@import "./app-theme";
@import "./globals";
@import "./creators-icons";
@import "./styles.dark";
@import "_animations.scss";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

html.infinite-scroll {
    height: 100%;
    display: block;
}

body {
    --border-color: #{$contrast-color};
    margin: 0;
    // min-height: calc(98vh - #{$header-height});
    min-height: calc(100svh - #{$header-height});
    background: $body-background;
    font-family: $font-family;
    letter-spacing: 0.5px;
    font-size: 20px;
    transition-duration: $default-duration;
    transition-property: background-color, color;

    .mat-mdc-snack-bar-container {
        .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
            color: $contrast-text;
        }
    }

    .mat-mdc-button.big-icon>.mat-icon {
        font-size: 2.125rem;
        height: 2.125rem;
        width: 2.125rem;
    }

    .min-datepicker.mat-mdc-form-field-has-icon-suffix {
        .mat-mdc-text-field-wrapper {
            padding: 0;
        }
        .mat-mdc-icon-button.mat-mdc-button-base {
            width: 30px;
            height: 30px;
            padding: 2px 0 0;
            .mat-mdc-button-touch-target {
                width: 30px;
                height: 30px;
            }
        }
    }
    .min-datepicker {
        .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
            padding-top: 0;
            padding-bottom: 0;
        }
        .mat-mdc-form-field-infix {
            width: 58px;
            padding: 0;
            min-height: 24px;
        }
        .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
            font-size: 11px;
            line-height: 15px;
        }
        .mat-mdc-form-field-label {
            font-size: 12px;
            line-height: 29px;
        }
    }
    .mat-mdc-form-field.mat-mdc-form-field-disabled.min-datepicker,
    .mat-mdc-form-field.mat-mdc-form-field-disabled.no-disabled {
        .mat-mdc-form-field-underline {
            background-size: unset;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 1000%);
        }
        .mat-input-element:disabled {
            color: inherit;
        }
    }

    mat-form-field.hide-subscript {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
    mat-form-field.no-padd, .no-padd-children {
        .mdc-text-field--filled, .mdc-text-field {
            padding-left: 4px;
            padding-right: 4px;
        }
        .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
            padding: .4375em 0;
            min-height: unset;
        }
    }

    .cdk-overlay-pane {
        max-width: calc(100% - 20px) !important;
    }

    // ENVIRONMENT BADGE
    &.stg,
    &.qa,
    &.dev {
        &::before {
            position: fixed;
            top: -20px;
            left: -40px;
            transform: rotateZ(-45deg);
            padding: 35px 35px 5px;
            color: $body-background;
            z-index: 1000;
        }
    }
    &.stg::before {
        content: 'STG';
        background-color: $warn;
    }
    &.qa::before {
        content: 'QA';
        background-color: $warn-accent;
    }
    &.dev::before {
        content: 'DEV';
        background-color: $tertiary;
    }
}

button {
  font-family: $font-family;
}

@media (min-width: $mobile-threshold) {
    body {
        font-size: 16px;
        // min-height: 98vh;
        min-height: 100svh;
    }
}

body.offline::after {
    position: fixed;
    content: 'Offline';
    top: -20px;
    right: -40px;
    transform: rotateZ(45deg);
    background-color: $warn;
    padding: 35px 35px 5px;
    color: $contrast-text;
    z-index: 1000;
}

body .mat-toolbar-row {
    height: 45px;
}

body .mat-toolbar {
    min-height: 45px;
}

.spinner {
    position: relative;
}

.spinner::after {
    content: ' ';
    position: absolute;
    border: solid $spinner-diameter $primary;
    border-bottom: solid $spinner-diameter transparent;
    border-left: solid $spinner-diameter transparent;
    width: 100%;
    height: 100%;
    top: -$spinner-diameter;
    left: -$spinner-diameter;
    animation: spin 2s ease-in-out infinite;
}

.spinner.round::after {
    border-radius: 50%;
}

mat-progress-bar.vertical {
    transform: rotate(90deg);
}

mat-progress-bar.main-progress-bar {
    position: fixed;
    z-index: 300;
    top: 0;
}
mat-progress-bar.main-progress-bar {

  .mat-progress-bar-background {
    opacity: 0;
  }

  .mat-progress-bar-fill::after {
    background-color: var(--hexa-purple);
  }

  .mat-progress-bar-buffer {
    background-color: var(--hexa-purple);
    opacity: .3;
  }
}

mat-sidenav {
    width: 100vw;
}

.flexcontainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

body .mat-drawer-container.mat-sidenav-container {
    background: $primary-gradient;
}

.view-wrap {
    margin: auto;
    max-width: $app-max-width;
    display: block;
    transition-duration: $default-duration;
    transition-property: max-width;
}

.view-min-height {
    min-height: calc(100vh - #{$footer-height} - #{$header-height});
}

.content-wrap.expand .view-wrap {
    max-width: 100%;
}

.ql-hidden {
    display: none;
}

[layout-padding] {
    padding: 8px;
}

.center-me, .mdc-button.center-me, .mdc-fab.center-me,
.mdc-icon-button.center-me, .mat-mdc-mini-fab.center-me,
.mdc-button--raised.center-me, .mat-select.center-me,
body .file-image .logo-wrap.center-me {
    display: block;
    margin: auto;
}

.center-inline-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.menu-img-btn.mat-mdc-menu-item {
    height: 114px;
    width: 150px;
    border-radius: 50%;
    transition-duration: $default-duration;

    img {
        height: 114px;
        width: 114px;
        border-radius: 50%;
        object-fit: cover;
    }
}

button.mat-mdc-menu-item.active {
    background-color: rgba(120, 120, 120, 0.25);
}

.option-img {
    position: absolute;
    right: 5px;
    height: 47px;
    width: 47px;
    object-fit: cover;
}

.material, .option-img.material {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.material::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
}

.hexa-link {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: $hexa-link;
  cursor: pointer;

  &:hover {
    color: $hexa-link-hover;
  }

  &:disabled {
    color: var(--hexa-black);
    cursor: default;
  }
}

.center-wrapper {
    .mdc-button-wrapper {
        margin: auto;
    }
}

.va-middle, [mat-button] .mdc-button-wrapper > *, [mat-raised-button] .mdc-button-wrapper > * {
    vertical-align: middle;
}

.va-bottom {
    vertical-align: bottom;
}

.va-super {
    vertical-align: super;
}

.va-text-bottom {
    vertical-align: text-bottom;
}

.va-text-top {
    vertical-align: text-top;
}

.va-sub {
    vertical-align: sub;
}

.va-top, .mdc-icon-button .mdc-button-wrapper > *.va-top {
    vertical-align: top;
}

body .va-unset {
    vertical-align: unset;
}

.content-va {
    content-visibility: auto;
}

.va-baseline {
    vertical-align: baseline;
}

re-captcha > div {
    margin: auto;
}

body {
    mat-slider.short.mat-slider-horizontal {
        min-width: 112px;
    }

    .mat-select {
        font-family: $font-family;
    }

    .mat-dialog-title {
        font-family: $font-family;
    }

    .mat-mdc-dialog-surface.mdc-dialog__surface {
        overflow-x: hidden;
    }

    .mdc-button, .mdc-button--raised {
        border-radius: $card-border-radius;
        line-height: 30px;
        font-size: 10px;
        letter-spacing: 1px;
        font-family: $font-family;

        .circle {
            border-radius: 50%;
            display: inline-block;
            width: 15px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            vertical-align: baseline;
            margin-left: 5px;
        }
    }

    .mdc-button.ghost {
        background: transparent;
        border: solid 1px;
        transition-duration: $default-duration;
    }

    .mdc-button.big-fs {
        font-size: 12px;
    }

    .mdc-button.ghost.invert {
        border-color: var(--hexa-black);
        background-color: var(--hexa-black);
        color: $contrast-text;
    }

    .mdc-button.ghost.primary {
        border-color: $primary;
        // color: $primary;
    }

    .mdc-button.ghost.primary.invert {
        border-color: $primary;
        color: $contrast-color;
        background-color: inherit;
    }

    .mdc-button.ghost.primary.invert.download {
        background-color: $box-alt-background;
    }

    .mdc-button--raised.mat-primary.mixed,
    .mdc-button--raised.mat-primary.mixed.mat-mdc-button:not(:disabled),
    .mdc-button.mat-primary.mat-mdc-raised-button.mixed.mat-mdc-button:not(:disabled),
    .mdc-button.mat-primary.mixed {
        background: $contrast-color;
        color: $secondary;
        border-color: $contrast-color;
    }

    .mdc-button--raised.mat-primary.invert {
        border-color: $contrast-color;
        color: $primary;
    }

    .mdc-button.mat-accent.invert, .mdc-button--raised.mat-accent.invert {
        border: solid 1px $contrast-color;
        color: $contrast-color;
        background: transparent;
    }

    .mdc-button--raised.thin, .mdc-button.thin {
        height: 25px;
        line-height: 18px;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        padding: 2px 10px;

        mat-icon {
            font-size: 18px;
            vertical-align: top;
            width: 19px;
            height: 19px;
        }
    }

    .mdc-button--raised:not([class*='mat-elevation-z']) {
        box-shadow: none;
    }

    .mdc-button.contrast.mat-primary, .mdc-button--raised.contrast.mat-primary {
        color: $contrast-color;
    }

    .mdc-button.huge, .mdc-button--raised.huge {
        border-radius: 40px;
        font-size: 20px;
        font-weight: $font-weight-bolder;
        line-height: 58px;
        letter-spacing: 2px;
        padding: 0 40px;
    }

    .mdc-button.huge.min-w, .mdc-button--raised.huge.min-w {
        min-width: 350px;
    }

    .mdc-button.big, .mdc-button--raised.big {
        border-radius: $card-border-radius;
        font-size: 14px;
        font-weight: $font-weight-bolder;
        line-height: 38px;
        letter-spacing: 1.5px;
        padding: 0 15px;
        min-width: 185px;
    }

    .mdc-button.big.short, .mdc-button--raised.big.short {
        min-width: unset;
        padding: 0 25px;
    }

    .mdc-button.thick, .mdc-button--raised.thick {
        border-radius: $card-border-radius;
        font-size: 14px;
        font-weight: $font-weight-bolder;
        line-height: 38px;
        letter-spacing: 1.5px;
        padding: 0 20px;

        .circle {
            width: 18px;
            height: 18px;
            line-height: 18px;
            color: var(--hexa-white);
        }
    }

    .mdc-button.thick.medium, .mdc-button--raised.thick.medium {
        line-height: 34px;
    }

    .mdc-button--raised.mat-primary,
    .mdc-button--raised.mat-primary.mat-mdc-button:not(:disabled) {
        background: $primary-gradient;
        color: $contrast-text;
    }

    .mdc-button--raised.mat-primary.active {
        background: $primary-gradient-active;
        background-size: 300%;
        animation: bg-animation 3s infinite;
    }

    .mdc-button.mat-accent,
    .mdc-button--raised.mat-accent,
    .mat-accent .mdc-button,
    .mdc-button.mat-accent.mat-mdc-button:not(:disabled),
    .mdc-button--raised.mat-accent.mat-mdc-button:not(:disabled),
    .mat-accent .mdc-button.mat-mdc-button:not(:disabled) {
        background: $accent-gradient;
        color: $contrast-text;
    }

    .mdc-button--raised.mat-accent[disabled], .mdc-button--raised[disabled] {
        color: $contrast-text-disabled;
    }

    .mdc-icon-button.mat-accent, .mdc-button {
        mat-icon {
            color: $accent-end;
        }

        mat-icon.contrast-text {
            color: $contrast-text;
        }
    }

    .mat-mdc-mini-fab.mat-primary {
        color: $contrast-text;
    }

    .mat-mdc-button:not(:disabled) {
        color: inherit;
    }

    .contrast-wrap *, mat-form-field.mat-mdc-form-field.contrast, app-autocomplete.contrast mat-form-field.mat-mdc-form-field {
        color: $contrast-text;
        --mdc-filled-text-field-label-text-color: $contrast-text;
        .mdc-floating-label {
            mat-label {
                color: $contrast-text;
            }
        }

        .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input::placeholder,
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder
        mat-form-field.mat-mdc-form-field.mat-mdc-form-field::placeholder,
        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
        .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
        mat-form-field.mat-mdc-form-field.mat-mdc-form-field,
        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
            color: $contrast-text !important;
            input {
                color: $contrast-text !important;
            }
            input::placeholder {
                color: $contrast-text !important;
            }
        }
        .mat-form-field-label, input.mat-mdc-input-element, input.mat-mdc-input-element::placeholder, .mat-mdc-select-placeholder {
            color: $contrast-text;
        }
        .mat-mdc-select-placeholder svg, .mat-mdc-select-arrow svg {
            filter: invert(1);
        }

        .mat-form-field-underline {
            background-color: $contrast-text;
        }

        .mat-hint {
            color: $contrast-text;
        }

        .mat-select-arrow, .mat-select-value {
            color: $contrast-text;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            .mdc-line-ripple::before {
                border-bottom-color: $contrast-text;
            }
        }
    }

    .mat-hint.contrast {
        color: $contrast-text;
    }

    .mat-mdc-form-field.mat-form-field-invalid.contrast .mat-form-field-label {
        color: $warn;
    }

    mat-form-field.mat-form-field-invalid.mat-mdc-form-field.contrast {
        .mat-form-field-underline {
            background-color: $warn;
        }
    }

    mat-form-field.mat-mdc-form-field {
        font-family: $font-family;
    }

    .mat-mdc-form-field.mat-focused {
        .mat-form-field-label {
            color: $gray-material;
        }
    }

    .has-approved {
        font-size: 24px;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
    }

    .has-approved.first {
        left: calc(50% - 22px);
    }

    .has-approved.second {
        left: calc(50% - 6px);
    }

    .check3 {
        width: 50%;
        height: 3px;
        top: calc(50% + 10px);
        left: 25%;
        background-color: $primary;
    }

    .fab-btn.mdc-fab {
        box-shadow: none;
        width: 56px;
        height: 56px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: transparent;
        margin: 10px 10px 0 0;
        opacity: .7;
        transition-duration: $default-duration;
        position: relative;
    }

    .fab-btn.mdc-fab.current, .fab-btn.mdc-fab.copy {
        opacity: 1;
    }

    .fab-btn.mdc-fab.copy {
        mat-icon {
            color: $contrast-color;
        }
    }

    .fab-btn.mdc-fab::before {
        content: '';
        width: 48px;
        height: 48px;
        display: inline-block;
        position: absolute;
        border: solid 4px $gray2;
        top: 0;
        left: 0;
        border-radius: 50%;
        transition-duration: $default-duration;
        transition-property: border;
        box-sizing: unset;
        margin: 0;
    }

    .fab-btn.mdc-fab.current::before {
        border-color: $primary;
    }
    .fab-btn.mdc-fab.no-marg {
      margin: 0 10px 0 0;
    }
    .mdc-button {
        text-transform: uppercase;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
}

app-file-upload.bg {
    background-color: rgba(190, 190, 190, 0.3);
}

app-file-upload.dashed {
    border: dashed 1px;
}

.file-image {
    app-file-upload.accent {
        button.mdc-icon-button.mat-accent {
            background-color: $contrast-text;
            color: $box-background;
        }
    }

    app-file-upload.primary {
        button.mdc-icon-button.mat-accent {
            background-color: $primary;
            color: $box-background;
        }
    }

    .logo-wrap {
        width: $file-diameter;
        height: $file-diameter;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        overflow: hidden;

        img {
            max-width: $file-diameter;
            max-height: $file-diameter;
            border-radius: 50%;
        }

        .img {
            display: inline-block;
            width: $file-diameter;
            height: $file-diameter;
            border-radius: 50%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .img.cover {
            background-size: cover;
        }

        button.delete-image.mdc-button {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition-duration: $default-duration;
            background-color: rgba(0, 0, 0, .2);
            width: 100%;
            z-index: 1;
            padding: 0;
            border-radius: 0;

            mat-icon {
                color: $box-background;
            }
        }
    }

    .logo-wrap:hover {
        button.delete-image.mdc-button {
            opacity: 1;
        }
    }

    .logo-wrap.center-me {

    }
}

.mat-divider.contrast {
    border-top-color: $gray2;
}

.mat-divider.gray10 {
    border-top-color: $gray10;
}

.mdc-button, .mdc-button--raised, .mdc-fab, .mat-mdc-mini-fab {
    text-transform: uppercase;
}

.commas:after {
    content: ', ';
}

.commas:last-child:after {
    content: '';
}

.w-100 {
    width: 100%;
}

.max-w-100 {
    max-width: 100%;
}

.max-w-60 {
  max-width: 60%;
}

.fadeInOut {
    animation-name: fadeInOut;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

.fadeInOut.slow-plus.animated {
    animation-duration: 5s;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

app-feedback-view img {
    max-width: 100%;
}

.hide-sm, .mat-toolbar.hide-sm, .mdc-icon-button.disp-block.hide-sm {
    display: none;
}

.default-color, .mat-menu-item .mat-icon-no-color.default-color {
    color: $contrast-color;
}

.white-color {
    color: white;
}

.white-bg {
    background-color: white;
}

.white-radial-gradient {
    // background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.black-bg {
    background-color: black;
}

.primary-color {
    color: $primary;
}

.primary-color-end {
    color: $primary-end;
}

.primary-bg {
    background-color: $primary-end;
}

.accent-color {
    color: $contrast-color;
}

.accent-bg {
    background-color: $contrast-color;
}

.gray18-bg {
    background-color: $gray18;
}

.tertiary-color {
    color: $tertiary;
}

.tertiary-bg {
    background-color: $tertiary;
}

.danger-color {
    color: $hexa-danger;
}

.hexa-success-color {
    color: $hexa-success;
}

.warn-color, .mat-mdc-list .mdc-list-item.warn-color {
    color: $warn;
}

.warn-bg, .mat-mdc-list .mdc-list-item.warn-color-bg {
    background-color: $warn-bg;
}

.warn-accent-color, .mat-mdc-list .mdc-list-item.warn-accent-color {
    color: $warn-accent;
}

.warn-accent-bg, .mat-mdc-list .mdc-list-item.warn-accent-bg {
    background-color: $warn-accent-bg;
}

.alt-bg, app-main-nav .mdc-icon-button.arrow::after {
    background-color: $box-alt-background;
}

.half-opacity-bg {
    background-color: rgba(255, 255, 255, 0.5);
}

.body-bg {
    background-color: $body-background;
}

.success-accent-color {
    color: $success;
}

.success-accent-bg {
    background-color: $success;
}

.round {
    border-radius: 50%;
}

@media (min-width: $tablet-threshold) {
    .hide-gt-sm {
        display: none !important;
    }
    .hide-sm {
        display: block;
    }
    .mat-toolbar.hide-sm {
        display: flex;
    }
}

body.incognito {
    .hide-incognito {
        display: none;
    }
}

body .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.82);
}

mat-checkbox.mdc-checkbox.white-space-normal {
    .mdc-checkbox-layout {
        white-space: normal !important;
    }
}

mat-checkbox.mdc-checkbox.line-height-small {
    .mdc-checkbox-label {
        line-height: 1.4em !important;
    }
}

mat-list.wrap, .flex-wrap {
    display: flex;
    flex-wrap: wrap;

    .scrollable-content {
        flex-wrap: wrap;
    }
}

mat-list.mat-mdc-list-base.customized {
    .mdc-list-item {
        display: inline-block;
        width: unset;

        .mdc-list-item-content {
            position: unset;
        }
    }
}

.flex-wrap > .cdk-virtual-scroll-content-wrapper {
    flex-flow: row wrap;
}

.warn {
    color: red;
}

mat-option.disabled {
    .mat-option-text, .mdc-list-item__primary-text {
        opacity: 0.4;
    }
}

.icon-x-fb {
    border-radius: 50%;
    position: relative;
    color: $contrast-text;
    background-color: $gray11;
    width: 16px;
    height: 16px;
    display: inline-block;
    text-align: center;
    line-height: 11px;
}

.icon-x-fb::before {
    content: 'x';
    font-family: Arial;
    font-size: 13px;
    vertical-align: baseline;
    width: 100%;
    text-align: center;
    padding-left: 1px;
}

.half-opacity {
    opacity: 0.5;
}

.text-center {
    text-align: center;
}

.text-dir {
    text-align: left;
}

.text-op-dir {
    text-align: right;
}

.font-sspro {
    font-family: 'Source Sans Pro';
}

.title {
    // font-family: "Open Sans","Helvetica Neue",sans-serif;
    text-transform: uppercase;
    // border-bottom: solid 5px #000;
    text-align: center;
    display: inline-block;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 3px;
    font-size: 16px;
}

.sub-title {
    text-transform: capitalize;
    color: $contrast-color;
    font-size: 14px;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
}

.color-normal {
    color: $contrast-color;
}

.color-on-dark {
    color: $on-button-dark;
}

.sub-title2, .link.sub-title2 {
    text-transform: capitalize;
    color: $accent-end;
    font-size: 18px;
    font-weight: normal;
}

.contrast-wrap {
    color: $contrast-text;
    background-color: $contrast-color;

    mat-list.mat-mdc-list-base {
        mat-list-item.mdc-list-item {
            color: $contrast-text;
        }
    }
}

app-users-autocomplete.contrast-wrap, .contrast-wrap {
    mat-form-field.mat-mdc-form-field.mat-mdc-form-field {
        color: $contrast-text;

        .mat-input-element, .mat-mdc-form-field-infix {
            background-color: transparent;
        }

        .mat-form-field-label {
            color: $contrast-text;
        }

        .mat-form-field-underline {
            background-color: $contrast-text;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
        border-bottom-color: $contrast-text;
    }

    .mat-slide-toggle-bar {
        background-color: rgba(255, 255, 255, 0.38);
    }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $hexa-purple
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color:  var(--hexa-white);
}

.mat-slide-toggle-bar {
    background-color: $gray20;
}

.mat-slide-toggle-thumb {
    background-color: var(--hexa-white);
}

.contrast-color {
    color: $contrast-color;
}

.text1 {
    font-size: 10px;
    color: $accent-end;
}

.text2 {
    font-size: 13px;
    color: $accent-end;
}

.bg-on-button, .mdc-button.bg-on-button {
    background-color: $on-button;
}

.bg-color-accent-end {
    background-color: $accent-end;
}

.color-accent-end {
    color: $accent-end;
}

.bold {
    font-weight: $font-weight-bold;
}

.bolder {
    font-weight: $font-weight-bolder;
}

.uppercase, body .mdc-button--raised.thin.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.tt-inherit, .sub-title2.tt-inherit, .link.sub-title2.tt-inherit, .sub-title.tt-inherit, .link.sub-title.tt-inherit {
    text-transform: inherit;
}

.no-text-transform, .sub-title2.no-text-transform {
    text-transform: unset;
}

.text-transform-none {
    text-transform: none;
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

.letter-spacing-05 {
    letter-spacing: .5px;
}

.capital-first::first-letter, .lowercase.capital-first::first-letter {
    text-transform: capitalize;
}
.underline, body .mdc-button.underline {
    text-decoration: underline;
}

.no-underline, a.no-underline {
    text-decoration: none;
}

.line-through {
    text-decoration: line-through;
}

.italic {
    font-style: italic;
}

.weight-normal {
    font-weight: normal;
}

.mdc-icon-button.small {
    width: 36px;
    height: 36px;

    mat-icon {
        font-size: 20px;
        line-height: 20px;
    }
}

.gray, .sub-title {
    color: $gray;
}

.gray2 {
    color: rgb(171, 171, 171);
}

.gray8 {
    color: $gray8;
}

.bg-gray {
    background-color: $box-alt-background;
    color: $accent-end;
}

.gray9 {
    color: $gray9;
}

.bg-gray6 {
    background-color: $gray6;
}
.bg-active {
  background-color: $active;
}
.rank-color {
    color: $rank;
}

.contrast-text {
    color: $contrast-text;
}

.contrast-text.invert-text {
    color: $contrast-color;
}

.contrast-bg {
    background-color: $contrast-text;
}

body .mdc-icon-button.contrast-text mat-icon {
    color: $contrast-text;
}

body .mdc-icon-button.contrast-text, body .mdc-button.contrast-text, body .mdc-button.contrast-text {
    mat-icon {
        color: $contrast-text;
    }
}

body .contrast-text {
    .mdc-list-item__primary-text, .mdc-list-item:hover .mdc-list-item__primary-text {
        --mdc-list-list-item-label-text-color: $contrast-text;
        --mdc-list-list-item-hover-label-text-color: $contrast-text;
    }
}

body .mat-tooltip {
    font-size: 12px;
    font-style: italic;
    font-family: $font-family;
}

body mat-card.mdc-card {
    font-family: $font-family;
}

body {
    .mat-slide-toggle-content {
        font-family: $font-family;
    }
}

.pos-rel {
    position: relative;
}

.pos-abs, .mdc-button.pos-abs, .mdc-fab.pos-abs, .mdc-icon-button.pos-abs, .mat-mdc-mini-fab.pos-abs,
    .mdc-button--raised.pos-abs, .mat-mdc-form-field.pos-abs, .mat-progress-bar.pos-abs,
    .pos-abs.mat-mdc-progress-spinner, .pos-abs.horizontal-align, .mdc-circular-progress.pos-abs {
    position: absolute;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.no-break {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.float-dir {
    float: left;
}

.float-op-dir {
    float: right;
}

.dir {
    direction: ltr;
}

.op-dir {
    direction: rtl;
}
.flex-op-dir, .mdc-button--raised.flex-op-dir, .mdc-icon-button.flex-op-dir, .mdc-button.flex-op-dir {
    margin-left: auto;
}

.clear {
    clear: both;
}

.disp-flex, mat-form-field.disp-flex .mdc-text-field {
    display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-baseline {
    align-items: baseline;
}

.flex-end {
  justify-content: flex-end;
}

.flex-align-center, mat-form-field.disp-flex .mdc-text-field {
  align-items: center;
}

.flex-content-center {
  justify-content: center;
}

.flex-content-start {
    justify-content: start;
  }

.space-evenly{
    justify-content: space-evenly;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
    flex-direction: row;
  }

.gap-24{
    gap: 24px;
}

.gap-4{
    gap: 4px;
}

.gap-7{
    gap: 7px;
}

.gap-8{
    gap: 8px;
}

.gap-15{
    gap: 15px;
}

.flex-row {
    flex-direction: row;
  }

.gap-24{
    gap: 24px;
}

.flex-1 {
  flex: 1;
}

.flex-space-around {
  justify-content: space-around;
}

.gap-10{
    gap: 10px;
}

.w-50 {
  width: 50%;
}

.align-self-stretch {
  align-self: stretch;
}

.border-box {
  box-sizing: border-box;
}

.pointer-none {
  pointer-events: none;
}

.disp-block, .mdc-button--raised.disp-block, .disp-block.mat-mdc-form-field {
    display: block;
}

.mat-form-field-long .mat-mdc-form-field-infix {
    width: 220px;
}

.mat-form-field-short .mat-mdc-form-field-infix {
    width: 95px;
}

.disp-ib {
    display: inline-block;
}

.default-duration {
    transition-duration: $default-duration;
}

.rotate {
    transform: rotate(90deg);
}

.divider {
    border-bottom: solid 1px #bbb;
    margin: 25px 0;
}

.theme-bg {
    background: $box-alt-background;
}

.box-content, mat-card.box-content.mdc-card:not([class*='mat-elevation-z']) {
    box-shadow: $box-shadow;
    padding: 20px;
    background: $box-alt-background;
    transition-duration: $default-duration;
    transition-property: box-shadow;
}

.box-content:not(.no-shadow-hover):hover, mat-card.box-content.mdc-card:not([class*='mat-elevation-z']):not(.no-shadow-hover):hover {
    box-shadow: $box-shadow-hover;
}

.box-bg {
    background-color: $box-background;
}

.box-title {
    // font-weight: 700;
    font-weight: 400;
    // color: #555;
    // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
}

.box-shadow {
    box-shadow: $box-shadow;
}

.box-shadow:not(.no-shadow-hover):hover, .box-shadow-hover {
    box-shadow: $box-shadow-hover;
}

.box-title.box-header {
    margin: -10px -10px 10px -10px;
    background-color: #fff;
    padding: 10px;
}

.bg-transparent, .box-title.box-header.bg-transparent {
    background-color: transparent;
}

.marg-bottom, .mat-divider.marg-bottom {
    margin-bottom: 15px;
}

.marg-top, .mat-divider.marg-top {
    margin-top: 15px;
}

.inline-link {
    color: unset;
}

.link {
    color: $gray;
    font-size: 12px;
}

.gray11, .link.gray11 {
    color: $gray11;
}

.gray12 {
    color: $gray12;
}

.gray15 {
    color: $gray15;
}

.bg-gray12 {
    background-color: $gray12;
}

.gray7 {
    color: $gray7;
}

.gray10 {
    color: $gray10;
}

.bg-gray14 {
    background-color: $gray14;
}

.bg-gray12-hover:hover {
    background-color: $gray12;
}

.bg-gray3, mat-expansion-panel.bg-gray3 {
    background-color: $gray3;
}

.link.primary-color {
    color: $primary;
    font-size: 14px;
    letter-spacing: 2.25px;
}

.link.primary-color-end {
    color: $primary-end;
    font-size: 14px;
    letter-spacing: 2.25px;
}

button.normalize {
    border: none;
    background: transparent;
    padding: 0;
}

.no-decoration, a.no-decoration {
    text-decoration: none;
}

.link-btn, .mdc-button.link-btn {
    display: inline;
    min-width: unset;
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
}

.corner {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 48px;
    height: 48px;
    z-index: 100;

    mat-icon {
        color: $body-background;
    }

    mat-icon.contrast-color {
        color: $contrast-color;
    }
}

.corner.warn {
    border-color: $warn;
    color: $body-background;
}

.corner::after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: -1;
    width: 0;
    height: 0;
    background-color: transparent !important;
    border-top: 0 solid transparent;
    border-right: 48px solid transparent;
    border-bottom: 48px solid transparent;
    border-left: 0 solid transparent;
    border-right-color: inherit;
    -webkit-transition: border-color .1s ease;
    transition: border-color .1s ease;
}

.corner.left::after {
    transform: rotate(270deg);
}

.corner.left {
    left: 0;
}

.corner.right {
    right: 0;
    text-align: right;
}

.corner.warn::after {
    border-right-color: $warn;
}

.corner.primary::after {
    border-right-color: $primary;
}

.corner.accent::after {
    border-right-color: $accent;
}

.corner.accent-end::after {
    border-right-color: $accent-end;
}

.corner.transparent::after {
    border-right-color: transparent;
}

.app-ranking-w {
    width: 352px;
}

// [fxlayoutalign="center center"] [fxflex] {
//     text-align: center;
// }
app-text-editor {
    display: block;

    .ql-disabled {
        .ql-editor {
            padding: 0;
        }
    }

    .ql-editor {
        img {
            max-width: 100%;
        }
    }

    .click-img {
        .ql-editor {
            img {
                cursor: pointer;
                box-shadow: $box-shadow;
                max-width: 95%;
                padding: 1.5%;
            }
        }
    }
}

app-text-editor.small-images {
    .ql-editor {
        img {
            max-height: 10vh;
        }
    }
}

.mat-mdc-list .mdc-list-item, * {
    .mdc-button.ellipsis {
        overflow: hidden;
    }
}

mat-chip-list.mat-chip-list mat-chip.mat-mdc-chip {
    max-width: 100%;
}

mat-chip-listbox.mat-mdc-chip-listbox {
    .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action {
        .mdc-evolution-chip__action--trailing {
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .mat-mdc-standard-chip.mdc-evolution-chip {
        .mdc-evolution-chip__action {
            max-width: 100%;
        }
    }
    mat-chip.mat-mdc-chip {
        max-width: 100%;
        .mdc-evolution-chip__text-label, .mat-mdc-chip-action-label {
            max-width: 100%;
        }
        .mdc-evolution-chip__cell {
            z-index: 10;
        }
        .mdc-evolution-chip__cell, .mdc-evolution-chip__text-label span {
            max-width: max(calc(100% - 35px), 250px);
            display: inline-block;
            vertical-align: text-bottom;
        }
    }
}

[hidden], .mat-mdc-list .mdc-list-item[hidden], .mdc-icon-button[hidden] {
    // display: none;
    opacity: 0;
    transform: scale(0);
}

mat-form-field.mat-form-field-disabled.normalize {
    input {
        color: inherit;
    }

    .mat-form-field-underline {
        opacity: 0;
    }
}

.pointer {
    cursor: pointer;
}

[hidden].hidden-none, .mat-mdc-list .mdc-list-item[hidden].hidden-none, .mdc-icon-button[hidden].hidden-none, .disp-none {
    display: none;
}

.mat-mdc-list.op-color .mdc-list-item {
    color: $body-background;
}

*:not(html):not(body)::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

*:not(html):not(body)::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

*:not(html):not(body)::-webkit-scrollbar-thumb {
    background-color: #999999;
}

// .scrollbar::-webkit-scrollbar-track, .mat-autocomplete-panel::-webkit-scrollbar-track, .mat-tab-body-content::-webkit-scrollbar-track, .mat-select-panel:not([class*='mat-elevation-z'])::-webkit-scrollbar-track, .mat-mdc-menu-panel::-webkit-scrollbar-track, .mat-mdc-dialog-container::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: #F5F5F5;
// }

// .scrollbar::-webkit-scrollbar, .mat-autocomplete-panel::-webkit-scrollbar, .mat-tab-body-content::-webkit-scrollbar, .mat-select-panel:not([class*='mat-elevation-z'])::-webkit-scrollbar, .mat-mdc-menu-panel::-webkit-scrollbar, .mat-mdc-dialog-container::-webkit-scrollbar {
//     width: 6px;
//     background-color: #F5F5F5;
// }

// .scrollbar::-webkit-scrollbar-thumb, .mat-autocomplete-panel::-webkit-scrollbar-thumb, .mat-tab-body-content::-webkit-scrollbar-thumb, .mat-select-panel:not([class*='mat-elevation-z'])::-webkit-scrollbar-thumb, .mat-mdc-menu-panel::-webkit-scrollbar-thumb, .mat-mdc-dialog-container::-webkit-scrollbar-thumb {
//     background-color: #999999;
// }

// body.images-menu .cdk-overlay-pane {
//     max-height: calc(100vh - 548px);
// }

// body.images-menu-big .cdk-overlay-pane {
//     max-height: calc(100vh - 200px);
// }

ul.decimal li {
    list-style-type: decimal;
}

ul.normalize {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

ul.padd-items {
    li {
        margin-bottom: 20px;
    }
}

.h1, .h1.title {
    font-size: 18px;
    // color: $gray8;
}

.h3 {
    font-size: 14px;
}

.h5 {
    font-size: 12px;
}

.or-buttons {
    position: relative;
}

.or-buttons::after {
    content: 'or';
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 5px;
    left: calc(50% - 13px);
    background-color: $box-alt-background;
    line-height: 24px;
    color: $gray;
}

.big-button {
    width: 100%;
    font-size: 1.3em;
    height: 48px;
}

.blur {
    filter: blur(5px);
}

snack-bar-container.success {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $primary;
        }
    }
}

snack-bar-container.error {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $warn;
        }
    }
}

snack-bar-container.info {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $contrast-text;
        }
    }
}

.space-top, .mdc-button--raised.space-top, .mat-divider.space-top {
    margin-top: 20px;
}

.space-top-double {
    margin-top: 40px;
}

.space-bottom, .mdc-button--raised.space-bottom {
    margin-bottom: 20px;
}
.horizontal-align {
    transform: translateX(-50%);
    left: 50%;
    position: relative;
}
.vertical-align {
    transform: translateY(-50%);
    top: 50%;
    position: relative;
}

.vertical-horizontal-align {
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    position: relative;
}

mat-accordion.low-pad {
    .mat-expansion-panel-body {
        padding: 0 5px 16px;
    }
}

mat-accordion.description-indent {
    .mat-expansion-panel-header-description {
        flex-grow: 0;
    }
}

mat-accordion.customized {
    mat-expansion-panel, .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: none;
        background: transparent;

        mat-expansion-panel-header.mat-expansion-panel-header {
            padding: 0 12px;
            font-size: 16px;
            font-family: $font-family;
        }

        mat-expansion-panel-header.mat-expansion-panel-header:hover {
            background: transparent;
        }
    }
}

mat-accordion.customized-title {
    mat-expansion-panel, .mat-expansion-panel:not([class*='mat-elevation-z']) {
        mat-expansion-panel-header.mat-expansion-panel-header {
            font-size: 16px;
            font-family: $font-family;
        }
    }
}

mat-tab-group.no-tabs {
    mat-tab-header.mat-mdc-tab-header {
        display: none;
    }
}

mat-tab-group.no-min-w {
    .mdc-tab__text-label {
        min-width: unset;
    }
}

mat-tab-group.uppercase {
    .mdc-tab__text-label {
        text-transform: uppercase;
    }
}

mat-tab-group.no-underline {
    .mat-tab-nav-bar, .mat-mdc-tab-header {
        border-bottom: none;
    }
}

mat-tab-group.small-text {
    .mat-tab-label, .mat-tab-label-content {
        font-size: 12px;
    }
}

mat-tab-group.big-text {
    .mdc-tab__text-label {
        font-size: 16px;
    }
}

mat-tab-group.h-100 {
    .mat-tab-body-wrapper {
        height: 100%;
    }
}

.h-100 {
  height: 100%;
}

mat-tab-group.padd {
    mat-tab-body {
        padding-top: 20px;
    }
}

mat-tab-group.mat-tab-group {
    font-family: $font-family;
}

mat-tab-group.mat-tab-group.customized {
    .mat-tab-label-active {
        opacity: 1;

        .mat-tab-label-content {
            color: $contrast-color;
            font-weight: $font-weight-bold;
        }
    }

    .mdc-tab__text-label {
        height: 40px;
        padding: 0;
        margin: 0 24px;
    }
}

mat-tab-group.mat-tab-group.customized.mat-primary {
    .mat-tab-label:not(.mat-tab-disabled):focus {
        background-color: unset;
    }
}

mat-tab-group.mat-tab-group.no-marg-first {
    .mat-tab-label:first-child {
        padding: 0;
        margin: 0;
        margin-right: 24px;
    }
}

mat-tab-group.mat-tab-group-no-overflow {
    .mat-mdc-tab-body-content {
        overflow: hidden;
    }
}

body .mat-ink-bar {
    height: 5px;
}

mat-button-toggle-group.customized, mat-button-toggle-group.mat-button-toggle-group-appearance-standard.customized {
    border-color: transparent;

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        border-color: transparent;
    }

    .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover {
        .mat-button-toggle-focus-overlay {
            opacity: 0;
        }
    }

    .mat-button-toggle + .mat-button-toggle {
        border: unset;
    }

    .mat-button-toggle-appearance-standard {
        background-color: transparent;
        border-radius: 50px;
        color: $contrast-text;
        margin: 0 5px;

        .mat-button-toggle-label-content {
            line-height: 20px;
            font-size: 16px;
        }
    }

    
    .mat-button-toggle.mat-button-toggle-appearance-standard, .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
        background-color: transparent;
        border: solid 1px transparent;
        color: inherit;
        border-radius: 50px;
        margin: 0 5px;
        .mat-button-toggle-label-content {
            line-height: 20px;
            font-size: 16px;
        }
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
        border: solid 1px $primary-end;
        color: $primary-end;
    }
}

mat-button-toggle-group.customized, mat-button-toggle-group.mat-button-toggle-group-appearance-standard.customized.tutorial-element {
    background: transparent;
}

@media (min-width: $mobile-threshold) {
    mat-button-toggle-group.customized, mat-button-toggle-group.mat-button-toggle-group-appearance-standard.customized {
        mat-button-toggle {
            min-width: 72px;
        }
    }
    .hide-gt-xs {
        display: none !important;
    }
    .hide-xs {
        display: block;
    }
}

.mat-sort-header-button.customized {
    font-size: 12px;
    letter-spacing: 1px;
}

app-main-nav {
    .mat-toolbar-single-row {
        height: $header-height;
    }
}

app-retailers-autocomplete.invert {
    mat-form-field {
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            input.mdc-text-field__input, input.mdc-text-field__input::placeholder {
                background-color: #444444;
                color: #ababab;
                border-radius: 50px;
                font-size: 17px;
                text-indent: 15px;
            }
        }
    }
}

mat-radio-group.contrast {
    .mat-radio-container {
        .mat-radio-outer-circle {
            border-color: $contrast-text;
        }
    }
}

mat-radio-group.customized {
    .mat-radio-button {
        margin: 0 25px 15px 0;
    }
}

mat-radio-group {
    display: flex;
    flex-direction: column;
    mat-radio-button {
        label.mdc-label {
            text-transform: capitalize;
        }
    }
}

app-checkbox-group {
    mat-checkbox {
        label.mdc-label {
            text-transform: capitalize;
        }
    }
}

mat-tree.customize {
    background-color: transparent;

    .mdc-checkbox-label {
        font-size: 13px;
        text-transform: capitalize;
        white-space: normal;
    }

    .mdc-checkbox-background {
        animation: none;
    }

    .mat-tree-node {
        min-height: 40px;
    }
}

mat-tree.contrast, mat-tree.customize.contrast {
    background-color: $contrast-color;
    color: $contrast-text;

    .mdc-checkbox-label, .mdc-label {
        color: $contrast-text;
    }

    .mdc-checkbox-background, input[type="checkbox"], .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border: solid 1px $contrast-gray;
    }

    .mdc-checkbox-anim-checked-unchecked .mdc-checkbox-background {
        .mdc-checkbox-checkmark {
            fill: $contrast-color;
        }
    }
}

mat-checkbox.contrast {
    .mdc-checkbox-background {
        border: solid 2px rgba(255, 255, 255, 0.8);
    }
}

.products-data {
    .image-data {
        width: 114px;
        height: 114px;
        display: inline-block;
        margin-left: 35px;
        border: solid 1px $gray5;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .overlay {
            position: absolute;
            background-color: rgba(0, 0, 0, .85);
            width: 100%;
            height: 100%;
            opacity: 0;
            transition-duration: $default-duration;
            z-index: 10;

            button.mdc-icon-button {
                color: $box-background;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                width: 50%;
            }

            .delete-image {
                left: 0;
            }

            .link-image {
                right: 0;
            }
        }

        .pin.left {
            right: unset;
            left: -13px;
        }

        .pin {
            transition-duration: $default-duration;
            opacity: 0;
            position: absolute;
            background-color: $box-background;
            padding: 3px;
            top: -15px;
            right: -13px;
            border-radius: 50%;
            z-index: 20;

            button.mdc-icon-button {
                background-color: $primary;
                width: 28px;
                height: 28px;
                line-height: 34px;

                .mdc-button-wrapper {
                    line-height: 32px;

                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .image-data:hover {
        .overlay, .pin {
            opacity: 1;
        }
    }

    .image-data.pinned {
        .pin {
            opacity: 1;
        }
    }
}

app-text-editor {
    position: relative;

    .ql-showHtml::after {
        content: "</>";
        font-weight: $font-weight-bolder;
    }
}

app-text-editor.customized {
    .ql-toolbar.ql-snow {
        border-bottom: none;
    }

    .ql-disabled {
        .ql-editor {
            padding: 0;
        }
    }
}

.item-border, .mdc-button.item-border {
    border: solid 1px $gray3;
}

app-text-editor.customized.round {
    border: solid 1px $gray3;
    border-radius: 42px;
    overflow: hidden;
    padding: 0 20px;

    .ql-toolbar.ql-snow {
        border: none;
    }

    .ql-container.ql-snow {
        border: none;
    }
}

app-text-editor.customized.with-button {
    padding-right: 185px;
}

app-text-editor.customized.box-alt-background {
    background-color: $box-alt-background;
}

.tutorial-curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 2000;
    animation: curtain-animation $default-duration ease-out forwards;
}

.tutorial-curtain.end {
    animation-name: curtain-end-animation;
}

.spin {
    animation: spin 2s linear infinite;
}

@keyframes curtain-animation {
    from {
        background: transparent;
    }
    to {
        background: rgba(0, 0, 0, 0.7);
    }
}

@keyframes curtain-end-animation {
    from {
        background: rgba(0, 0, 0, 0.7);
    }
    to {
        background: transparent;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.tutorial-element {
    position: relative;
    background-color: var(--hexa-white);
    z-index: 2010;
}

.tutorial-prompt {
    position: absolute;
    background-color: var(--hexa-white);
    z-index: 2010;
    padding: 15px 20px;
    border-radius: $border-radius;
    box-sizing: border-box;
}

.tutorial-inner {
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: $border-radius;
    margin-top: 10px;
    color: #fff;
    text-align: left;
}

.tutorial-title {
    text-transform: uppercase;
    margin: 0;
    font-size: 17px;
}

.tutorial-btn {
    border: none;
    cursor: pointer;
    color: var(--hexa-white);
    background: $primary-gradient;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 13px;
    text-transform: capitalize;
    display: inline-block;
}


.tutorial-prev {
    margin-right: 15px;
}

.tutorial-desc {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0;
}

.tutorial-tip {
  text-transform: none;
  background-color: $hexa-grey-light;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0;
  padding: 1rem;
  border-radius: $small-border-radius;
  margin-bottom: 1.6rem;
  font-weight: 400;
}

.tutorial-tip-header {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.7rem;
  margin-bottom: 0.4rem;
}

.tutorial-footer {
    margin-top: 8px;
    padding-top: 8px;
    border-top: solid 1px rgba(255, 255, 255, 0.5);
    font-size: 11px;
}

.tutorial-skip {
    text-decoration: underline;
    float: right;
    cursor: pointer;
}

.tutorial-read {
    float: right;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 22px;
    height: 16px;
    line-height: 22px;
    top: -4px;
    position: relative;
}

.blink {
    animation: blink-animation 1.5s alternate infinite ease-out;
}

.invert-theme {
    filter: invert(1);
}

.debug-canvas {
  opacity: 0.01;
  transform: scale(0.1);
}
@keyframes blink-animation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.blink-fast {
    animation: blink-fast-animation 1.5s alternate infinite ease-out;
}

@keyframes blink-fast-animation {
    0% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.blink-hue {
    animation: blink-hue 1.5s alternate infinite ease-out;
}

@keyframes blink-hue {
    from {
        filter: hue-rotate(-30deg);
    }
    to {
        filter: hue-rotate(30deg);
    }
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}

.shake {
    animation-name: shake;
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: $default-duration;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-out {
    animation-name: fadeOut;
    animation-duration: $default-duration;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.multi-images {
    img {
        object-fit: cover;
        object-position: center;
    }

    .b-l {
        // border-left: solid 2px $contrast-text;
        border-left: solid 2px transparent;
    }

    .b-b {
        // border-bottom: solid 2px $contrast-text;
        border-bottom: solid 2px transparent;
    }

    .up-down-wrap {
        height: 130px;

        img {
            height: 50%;
            max-height: 50%;
        }
    }
}

.bg-hexa-black {
  background-color: var(--hexa-black);
}

@keyframes bg-animation {
    0% {
        background-position: left
    }
    50% {
        background-position: right
    }
    100% {
        background-position: left
    }
}

@keyframes scale-in {
    0% {transform: scale(1);}
    50% {transform: scale(1.5);}
    100% {transform: scale(1);}
}
