
@keyframes float {
	0% {
		filter: drop-shadow(2px 0 20px rgba(0, 0, 0, 0.7));
		transform: translatey(0px);
	}
	50% {
		filter: drop-shadow(2px 15px 20px rgba(0, 0, 0, 0.5));
		transform: translatey(-5%);
	}
	100% {
		filter: drop-shadow(2px 0 20px rgba(0, 0, 0, 0.7));
		transform: translatey(0px);
	}
}
