@use '@angular/material' as mat;
// @import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();
// @include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();
// teal gradient
// background: linear-gradient(199.34deg, #27D1E3 0%, #1FDDB1 100%);
// black gradient
// background: linear-gradient(248.64deg, #040404 0%, #3A3C4E 100%);
// purple
// #904ee6

$app-theme-primary: mat.m2-define-palette(mat.$m2-purple-palette, 600, 800);
$app-theme-accent: mat.m2-define-palette(mat.$m2-grey-palette, 900, 100, 300);
$app-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// $app-theme: mat.define-light-theme(
//     $app-theme-primary,
//     $app-theme-accent,
//     $app-theme-warn
// );

$app-theme: mat.m2-define-light-theme((
 color: (
   primary: $app-theme-primary,
   accent: $app-theme-accent,
   warn: $app-theme-warn,
 ),
 typography: mat.m2-define-typography-config(),
 density: 0,
));

// @include mat.all-legacy-component-themes($app-theme);
@include mat.all-component-themes($app-theme);

// Our dark theme
.dark {
    // color: $light-primary-text;
    color: #fff;
    $dark-primary: mat.m2-define-palette(mat.$m2-purple-palette, 600, 800);
    $dark-accent: mat.m2-define-palette(mat.$m2-gray-palette);
    $dark-warn: mat.m2-define-palette(mat.$m2-red-palette);
    $dark-theme: mat.m2-define-dark-theme($dark-primary, $dark-accent, $dark-warn);

    // @include mat.all-legacy-component-themes($dark-theme);
    @include mat.all-component-themes($dark-theme);
  }

// @font-face {
//   font-family: "Montserrat";
// }
// $custom-typography: mat.define-legacy-typography-config(
//   $font-family: "Montserrat"
// );
$custom-typography: mat.m2-define-typography-config(
  $font-family: "Montserrat"
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
@include mat.all-component-typographies($custom-typography);
// @include mat.legacy-core();
