/* ********************************************************** */
/* ****** TO DO => Merge the old UI vars to the new UI ****** */
/* ********************************************************** */

$app-max-width: 1270px;
$default-duration: 0.35s;
$box-background: #ccc;
$box-background-dark: #333;
$box-alt-background: #fff;
$body-background: #f3f3f3;
$contrast-text: #fff;
$contrast-text-disabled: rgba(255, 255, 255, 0.6);
$contrast-gray: rgb(171, 171, 171);
$contrast-color: #000000;
$online-color: #0f0;
$primary: #664681;
$primary-end: #664681;
$secondary: #f9d1f0;
$primary-gradient: linear-gradient(104.73deg, #57059c -13.01%, #9e3699 40.99%, #e6afc6 110.41%);
$primary-gradient-active: linear-gradient(199.34deg, #46008c 0%, #ffc2dc 100%);
$warn: #f44336;
$warn-bg: rgb(247, 214, 211);
$warn-bg-dark: #611e19;
$warn-accent: #ff9300;
$warn-accent-bg: rgb(253, 223, 181);
$warn-accent-bg-dark: #7d5217;
$accent: #212121;
$accent-opacity-dark: rgba(33, 33, 33, 0.5);
$accent-gradient: linear-gradient(248.64deg, #040404 0%, #3a3c4e 100%);
$accent-gradient-dark: linear-gradient(248.64deg, #dadada 0%, #fff 100%);
$accent-end: #3a3c4e;
$tertiary: #904ee6;
$success: #00d684;
$file-diameter: 114px;
$spinner-diameter: 4px;
$gray: #717171;
$gray2: #bfbfbf;
$gray3: #e2e2e2;
$gray4: rgba(226, 226, 226, 0.445);
$gray5: #dcdcdc;
$gray6: #ededed;
$gray7: #0a0a0a;
$gray8: #3a3c4e;
$gray9: #252525;
$gray10: #545454;
$gray11: #737581;
$gray12: #383838;
$gray13: #d8d8d8;
$gray14: rgba(255, 255, 255, 0.9);
$gray15: #949494;
$gray16: rgba(0, 0, 0, 0.9);
$gray17: #919191;
$gray18: #cccccc;
$gray19: #D2D2D6;
$gray20: #9E9E9E;
$gray21: #d2d2d6;
$gray22: #797985;
$active: rgba(0, 0, 0, 0.04);
$active:            rgba(0, 0, 0, 0.04);
$in-progress: #00a1ff;
$job-pending-review: #ff9300;
$job-has-feedback: #ff2b35;
$pending-secondary-review: #ff9300;
$approved-job: #00d684;
$gray-material: rgba(0, 0, 0, 0.54);
$gray-material-dark: rgba(255, 255, 255, 0.46);
// $galvanized:        rgba(0,0,0,0.7);
$rank: gold;
$yelow: #FAB713;
$header-height: 59px;
$footer-height: 140px;
// $box-shadow:         0 0 10px 0 rgba(0,0,0,0.09);
$box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.1);
$box-shadow-dark: 0px 2px 15px -1px rgba(255, 255, 255, 0.4);
$box-shadow-hover: 0 4px 2px -3px rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 1px 5px 0 rgba(0, 0, 0, 0.22);
$box-shadow-dark-hover: 0px 2px 15px -1px rgba(255, 255, 255, 0.9);
$conversation-width: 300px;
$conversation-height: 400px;
$sidebar-width: 300px;
$sidebar-wide-width: 300px;
$mobile-threshold: 600px;
$tablet-threshold: 960px;
$mobileScreen: "(max-width: 599px)";
$tabletScreen: "(min-width: 600px) and (max-width: 999px)";
$notebookScreen: "(min-width: 1000px) and (max-width: 1500px)";
$desktopScreen: "(min-width: 1501px)";
$newUImobileScreen: "screen and (max-width: 959px)";
$newUINotebooksScreen: "screen and (min-device-width: 960px) and (max-device-width: 1600px)";
$newUIdesktopScreen: "screen and (min-width: 1601px)";
// $font-family:         'Arial', sans-serif;
$font-family: "Montserrat", sans-serif;
// $font-family:         "Lato","Open Sans", sans-serif;
// $font-weight-bolder:  900;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$job-card-width: 276px;
$job-card-height: 453px;
$small-border-radius: 5px;
$medium-border-radius: 6px;
$border-radius: 8px;
$card-border-radius: 8px;
$large-border-radius: 20px;
$default-pad: 20px;
$on-button: rgba(255, 255, 255, 0.5);
$on-button-dark: rgba(0, 0, 0, 0.5);
$priority-5: #ce0000;
$priority-4: #ea4444;
$priority-3: #ea7d24;
$priority-2: #2a8735;
$priority-1: #55a557;
$inactive-opacity: 0.5;


$hexa-purple: #8747BD;
$hexa-purple-accent-1: #9E3699;
$hexa-purple-darker: #4B2B64;
$hexa-purple-medium: #673C8B;
$hexa-purple-medium-2: #4B2768;
$hexa-purple-light: #6E399B;
$hexa-primary: #57059C;
$hexa-danger: #B42318;
$hexa-success: #039855;
$hexa-grey: #4F4F4F;
$hexa-grey-light: #FFFFFF1A;
$hexa-link: #2D12D6;
$hexa-link-hover: #0E0288;
$hexa-disabled-text: #A5A5AD;
$pink: #F5EDFB;
$hexa-selected-checked: #038148;
  /* ******************************************************* */
/* *********** NEW GLOBAL VARS (C3D Redesign) ************ */
/* ******************************************************* */

:root {
  // UI DIMENSIONS
  --header-height: 59px;
  --mobile-bottom-nav-height: 48px;

  // UI COLORS
  /* ****** TO DO ****** => RENAME VARS BY DESIGN (text primary, bg-primary etc...) */
  --hexa-black: #1f1f33;
  --hexa-white: #fff;
  --hexa-grey: #4f4f4f;
  --hexa-blue: #afc1f2;
  --hexa-purple: #664681;
  --hexa-dark-purple: #3c3c4e;
  --hexa-red: #EC3841;
  --bg-gray: #f2f5fd;
  --gray-btn: #edf0fc;
  --gray-btn-hover: #dcdfec;
  --hexa-primary-gradient: linear-gradient(104.73deg, #57059c -13.01%, #9e3699 40.99%, #e6afc6 110.41%);
  --hexa-primary-gradient-darker: linear-gradient(0deg, rgba(31, 31, 51, 0.24), rgba(31, 31, 51, 0.24)), linear-gradient(104.73deg, #57059C -13.01%, #9E3699 40.99%, #FFC2DC 110.41%);

  --notification-color: var(--hexa-red);

  --border-radius: 0.8rem;
  --card-border-radius: 0.8rem;

  --hexa-dark:                             #4C4C5C;
  --hexa-light:                            #E9E9EB;
  --hexa-light-blue:                       #F7FAFC;
  --hexa-purple-lighter:                   #C2B1D1;
  --hexa-grey-accent-1:                    #F0F2F5;
  --hexa-speration-gray:                   #CECECE;
  --hexa-grey-light-2:                     #F4F4F5;
  --hexa-grey-lighter:                     #E6E6E6;
}

:root body.dark {
  --hexa-black:                            #FFFFFF;
  --hexa-white:                            #1F1F33;
  --hexa-dark:                             #E9E9EB;
  --hexa-light:                            #4C4C5C;
  --hexa-light-blue:                       #080503;
  --hexa-purple-lighter:                   #464646;
  --hexa-grey-accent-1:                    #0F0D0A;
  --hexa-speration-gray:                   #313131;
  --hexa-grey-light-2:                     #0B0B0A;
  --hexa-grey-lighter:                     #191919;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(scale-fade) {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(fade-in) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
