@import "./globals";
body.dark {
    background: $contrast-color;
    color: $contrast-text;
    --border-color: #{$contrast-text};
    .mat-progress-bar.mat-accent {
        .mat-progress-bar-fill::after {
            background-color: $accent;
        }
    }
    .material::after {
        background: radial-gradient(ellipse at center, rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%);
    }
    .mdc-button.ghost.primary.invert {
        color: $contrast-text;
    }
    .mdc-button.ghost.primary.invert.download {
        background-color: $contrast-color;
    }
    .mdc-button--raised.mat-primary.mixed {
        background: $contrast-text;
        color: $primary;
        border-color: $contrast-text;
    }
    .mdc-button--raised.mat-primary.invert {
        border-color: $contrast-text;
    }
    .mdc-button.contrast.mat-primary, .mdc-button--raised.contrast.mat-primary {
        color: $contrast-text;
    }
    .mdc-button.mat-accent.invert, .mdc-button--raised.mat-accent.invert {
        border: solid 1px $contrast-text;
        color: $contrast-text;
        background: transparent;
    }
    .mdc-button--raised.mat-primary {
        color: $contrast-text;
        background: $primary-gradient;
    }
    .mdc-button--raised.mat-primary.active {
        background: $primary-gradient-active;
        background-size: 300%;
    }
    .mdc-button.mat-accent, .mdc-button--raised.mat-accent, .mat-accent .mdc-button {
        background: $accent-gradient-dark;
        color: $contrast-color;
    }
    .mdc-icon-button.mat-accent, .mdc-button {
        mat-icon {
            color: $contrast-text;
        }
        mat-icon.contrast-text {
            color: $contrast-color;
        }
    }
    .mat-mdc-mini-fab.mat-primary {
        color: $contrast-color;
    }
    mat-form-field.mat-mdc-form-field.contrast, app-autocomplete.contrast mat-form-field.mat-mdc-form-field {
        color: $contrast-color;
        .mat-form-field-label, input.mat-mdc-input-element, input.mat-mdc-input-element::placeholder, .mat-mdc-select-placeholder {
            color: $contrast-color;
        }
        .mat-form-field-underline {
            background-color: $contrast-color;
        }
        .mat-hint {
            color: $contrast-color;
        }
        .mat-select-arrow, .mat-select-value {
            color: $contrast-color;
        }
    }
    mat-form-field.mat-mdc-form-field.contrast.white-color, app-autocomplete.contrast mat-form-field.mat-mdc-form-field.white-color {
        color: $contrast-text;
        .mat-form-field-label, input.mat-mdc-input-element, input.mat-mdc-input-element::placeholder {
            color: $contrast-text;
        }
        .mat-form-field-underline {
            background-color: $contrast-text;
        }
        .mat-hint {
            color: $contrast-text;
        }
        .mat-select-arrow, .mat-select-value {
            color: $contrast-text;
        }
    }
    .mat-hint.contrast {
        color: $contrast-color;
    }
    .mat-form-field.mat-focused {
        .mat-form-field-label {
            color: $gray-material-dark;
        }
    }
    .file-image {
        app-file-upload.accent {
            button.mdc-icon-button.mat-accent {
                background-color: $contrast-color;
                color: $box-background-dark;
            }
        }
        app-file-upload.primary {
            button.mdc-icon-button.mat-accent {
                color: $box-background-dark;
            }
        }
        .logo-wrap {
            button.delete-image.mdc-button {
                background-color: rgba(255,255,255,.2);
                mat-icon {
                    color: $box-background-dark;
                }
            }
        }
    }
    .mat-divider.contrast {
        border-top-color: $gray8;
    }
    .mat-divider.gray10 {
        border-top-color: $gray15;
    }
    .warn-bg, .mat-mdc-list .mdc-list-item.warn-color-bg {
        background-color: $warn-bg-dark;
    }
    .warn-accent-bg, .mat-mdc-list .mdc-list-item.warn-accent-bg {
        background-color: $warn-accent-bg-dark;
    }
    .default-color, .mat-menu-item .mat-icon-no-color.default-color {
        color: $contrast-text;
    }
    .body-bg {
        background-color: $contrast-color;
    }
    app-main-nav {
        mat-toolbar.mat-toolbar.mat-primary {
            background: $primary-gradient;
        }
    }
    .accent-color {
        color: $contrast-text;
    }
    .accent-bg, .mdc-fab.mat-accent.accent-bg {
        background-color: $contrast-text;
    }
    .alt-bg, app-main-nav .mdc-icon-button.arrow::after {
        background-color: $accent;
    }
    .cdk-overlay-dark-backdrop {
        background: rgba(255, 255, 255, 0.82);
    }
    .icon-x-fb {
        color: $contrast-color;
        background-color: $contrast-text;
    }
    .sub-title {
        color: $contrast-text;
    }
    .sub-title2, .link.sub-title2 {
        color: $contrast-text;
    }
    .contrast-wrap {
        color: $contrast-color;
        background-color: $contrast-text;
        mat-list.mat-mdc-list-base {
            mat-list-item.mdc-list-item {
                color: $contrast-color;
            }
        }
        * {
            color: $contrast-color;
        }
    }
    .bg-on-button {
        background-color: $on-button-dark;
    }
    .bg-color-accent-end {
        background-color: $contrast-text;
    }
    .color-accent-end {
        color: $contrast-text;
    }
    .color-normal {
        color: $contrast-text;
    }
    .color-on-dark {
        color: $on-button;
    }
    app-users-autocomplete.contrast-wrap, .contrast-wrap {
        mat-form-field.mat-form-field.mat-mdc-form-field {
            color: $contrast-color;
            .mat-form-field-label {
                color: $contrast-color;
            }
            .mat-form-field-underline {
                background-color: $contrast-color;
            }
        }
        .mat-slide-toggle-bar {
            background-color: rgba(0, 0, 0, 0.38);
        }
    }
    .contrast-color {
        color: $contrast-text;
    }
    .text1 {
        color: $contrast-text;
    }
    .text2 {
        font-size: 13px;
        color: $contrast-text;
    }
    .gray {
        color: $gray17;
    }
    .gray8 {
        color: $gray13;
    }
    .mdc-card {
        background-color: $accent;
    }
    .bg-gray {
        background-color: $accent;
        color: $contrast-text;
    }
    .half-opacity-bg {
        background-color: $accent-opacity-dark;
    }
    .mat-mdc-dialog-container {
        background-color: $accent;
    }
    .bg-gray6 {
        background-color: $gray9;
    }
    .gray9 {
        color: $gray6;
    }
    .contrast-text {
        color: $contrast-color;
    }
    .contrast-text.white-color {
        color: white;
    }
    .contrast-text.invert-text {
        color: $contrast-text;
    }
    .contrast-bg {
        background-color: $accent;
    }
    body .mdc-icon-button.contrast-text mat-icon {
        color: $contrast-color;
    }
    body .mdc-icon-button.contrast-text, body .mdc-button.contrast-text, body .mdc-button.contrast-text {
        mat-icon {
            color: $contrast-color;
        }
    }
    .box-bg {
        background-color: $gray10;
    }
    .theme-bg {
        background: $accent;
    }
    .box-content, mat-card.box-content.mdc-card:not([class*='mat-elevation-z']) {
        box-shadow: $box-shadow-dark;
        background: $accent;
    }
    .box-content:not(.no-shadow-hover):hover, mat-card.box-content.mdc-card:not([class*='mat-elevation-z']):not(.no-shadow-hover):hover {
        box-shadow: $box-shadow-dark-hover;
    }
    .invert-box-content {
        filter: invert(0.87);
    }
    .box-title {
        color: rgba(255, 255, 255, 0.87);
    }
    .box-title.box-header {
        background-color: var(--hexa-black);
    }
    .bg-transparent, .box-title.box-header.bg-transparent {
        background-color: transparent;
    }
    .box-shadow, mat-card.box-shadow.mdc-card:not([class*='mat-elevation-z']) {
        box-shadow: $box-shadow-dark;
    }
    .box-shadow:hover, mat-card.box-shadow.mdc-card:not([class*='mat-elevation-z']):hover {
        box-shadow: $box-shadow-dark-hover;
    }
    .bg-gray14 {
        background-color: $gray16;
    }
    .gray11, .link.gray11 {
        color: $gray2;
    }
    .gray12 {
        color: $gray3;
    }
    .corner {
        mat-icon {
            color: $contrast-color;
        }
        mat-icon.contrast-color {
            color: $contrast-text;
        }
    }
    .corner.warn {
        color: $contrast-color;
    }
    .link {
        color: $gray15;
    }
    .link.primary-color {
        color: $primary;
    }
    .bg-gray3, mat-expansion-panel.bg-gray3 {
        background-color: $gray9;
    }
    .bg-gray12 {
        background-color: $gray2;
    }
    .bg-gray12-hover:hover {
        background-color: $gray2;
    }
    .gray7 {
        color: $contrast-text;
    }
    .gray10 {
        color: $gray2;
    }
    .corner.accent::after {
        border-right-color: $contrast-text;
    }
    .corner.accent-end::after {
        border-right-color: $contrast-text;
    }
    .mat-mdc-list.op-color .mdc-list-item {
        color: $contrast-color;
    }
    .or-buttons::after {
        background-color: $accent;
        color: $gray;
    }
    snack-bar-container.info {
        simple-snack-bar {
            .mat-simple-snackbar-action {
                color: $contrast-color;
            }
        }
    }
    mat-tab-group.mat-tab-group.customized {
        .mat-tab-label-active {
            .mat-tab-label-content {
                color: $contrast-text;
            }
        }
    }
    mat-button-toggle-group.customized:not(.contrast-text), mat-button-toggle-group.mat-button-toggle-group-appearance-standard.customized:not(.contrast-text) {
        .mat-button-toggle-appearance-standard {
            color: $contrast-color;
        }
    }
    app-retailers-autocomplete.invert {
        mat-form-field {
            .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                input.mdc-text-field__input, input.mdc-text-field__input::placeholder {
                    color: $box-background-dark;
                    background-color: #bbb;
                    color: #545454;
                }
            }
        }
    }
    mat-radio-group.contrast {
        .mat-radio-container {
            .mat-radio-outer-circle {
                border-color: $contrast-color;
            }
        }
    }
    mat-radio-group.contrast.white-color {
        .mat-radio-container {
            .mat-radio-outer-circle {
                border-color: white;
            }
        }
    }
    .mat-tree {
        background-color: $contrast-color;
    }
    mat-tree.contrast, mat-tree.customize.contrast {
        background-color: $contrast-text;
        color: $contrast-color;
        .mdc-checkbox-label, .mdc-label {
            color: $contrast-color;
        }
        .mdc-checkbox-anim-checked-unchecked .mdc-checkbox-background {
            .mdc-checkbox-checkmark {
                fill: $contrast-text;
            }
        }
    }
    mat-checkbox.contrast {
        .mdc-checkbox-background {
            border: solid 2px rgba(0,0,0,0.8);
        }
    }
    .products-data {
        .image-data {
            border: solid 1px $gray9;
            .overlay {
                background-color: rgba(255, 255, 255, .85);
                button.mdc-icon-button {
                    color: $box-background-dark;
                }
            }
            .pin {
                background-color: $box-background-dark;
            }
        }
    }
    app-text-editor.customized.round, .item-border, .mdc-button.item-border {
        border: solid 1px $gray9;
    }
    app-text-editor.customized.box-alt-background {
        background-color: $accent;
    }
    @keyframes curtain-animation {
        from {
            background: transparent;
        }
        to {
            background: rgba(255,255,255,0.7);
        }
    }

    @keyframes curtain-end-animation {
        from {
            background: rgba(255,255,255,0.7);
        }
        to {
            background: transparent;
        }
    }

    .tutorial-element {
        background: var(--hexa-black);
    }

    .tutorial-prompt {
        background: var(--hexa-black);
    }

    .tutorial-inner {
        background: rgba(255, 255, 255, 0.7);
        color: var(--hexa-black);
    }

    .tutorial-btn {
        border: solid 1px $contrast-text;
        color: $contrast-text;
    }
    .tutorial-footer {
        border-top: solid 1px rgba(0, 0, 0, 0.5);
    }
    .tutorial-read {
        color: var(--hexa-black);
    }
    // .multi-images {
    //     .b-l {
    //         border-left: solid 2px $contrast-color;
    //     }
    //     .b-b {
    //         border-bottom: solid 2px $contrast-color;
    //     }
    // }
    .ql-editor.ql-blank::before, .ql-snow .ql-stroke, .ql-snow .ql-picker, .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
        color: $contrast-gray;
        stroke: $contrast-gray;
    }
    .invert-theme {
        filter: unset;
    }
}
